window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');

document.addEventListener("DOMContentLoaded", function() {
    window.onload = function () {
        showHideNavLogo();
        hoverDropdown();
        showSideMobileMenuItems();
    };

    window.onscroll = function () {
        showHideNavLogo();
    };

    window.onresize = function () {
        showHideNavLogo();
    }
});

window.addEventListener('resize', windowResize);
// window.addEventListener('resize', showHideNavLogo);

function windowResize() {
    hoverDropdown();

    showSideMobileMenuItems();
    // showHideNavLogo()
}

function showSideMobileMenuItems() {
    let personalMenuHeader = document.getElementById('navbarDropdown2');
    let personalMenu = document.getElementById('personalMenu')
    if (window.innerWidth < 992) {
        personalMenuHeader.style.display='none';
        personalMenu.classList.add('show');
    } else {
        personalMenuHeader.style.display='block';
        personalMenu.classList.remove('show');
    }
}

function showHideNavLogo() {
    let navbar = document.getElementById('main-nav');
    let scrollLogo = document.getElementById("scroll-logo");

    let logoCollapse = new bootstrap.Collapse(scrollLogo, {
        toggle: false
    });

    if (window.innerWidth >= 992) {
        let navbarSpace = navbar.getBoundingClientRect().top;
        // let logoDisplay = scrollLogo.style.display;

        // console.log('top: ' + navbarSpace);

        if (navbarSpace === 0) {
        // if (navbarSpace <= 0 && logoDisplay !== 'block') {
            navbar.classList.add('bg-white');
            logoCollapse.show()
            // fadeIn(scrollLogo);
        } else if (navbarSpace >= 1) {
        // } else if (navbarSpace> 0 && logoDisplay !== 'none') {
            navbar.classList.remove('bg-white');
            logoCollapse.hide()
            // fadeOut(scrollLogo);
        }
    } else {
        scrollLogo.style.display = 'none';
    }
}



function fadeIn(element) {

    let opacity = 0.1;  // initial opacity
    element.style.display = 'block';
    let timer = setInterval(function () {
        if (opacity >= 1){
            clearInterval(timer);
        }
        element.style.opacity = opacity;
        element.style.filter = 'alpha(opacity=' + opacity * 100 + ")";
        opacity += opacity * 0.3;
    }, 10);
}

function fadeOut(element) {

    let opacity = 1;  // initial opacity
    let timer = setInterval(function () {
        if (opacity <= 0.1){
            clearInterval(timer);
            element.style.display = 'none';
        }
        element.style.opacity = opacity;
        element.style.filter = 'alpha(opacity=' + opacity * 100 + ")";
        element.animate({"width" : 0});
        opacity -= opacity * 0.3;
    }, 50);
}

function hoverDropdown() {
    // Have dropdown elements expand on hover for larger screen sizes

    // console.log('width: ' + window.innerWidth);
    if (window.innerWidth >= 992) {
        document.querySelectorAll('.cat-filter-wrapper').forEach(function(everyItem){
            everyItem.addEventListener('mouseover', function(e){
                let el_link = this.querySelector('a[data-bs-toggle]');
                if (el_link != null){
                    let nextEl = el_link.nextElementSibling;
                    el_link.classList.add('show');
                    nextEl.classList.add('show');
                }
            });
            everyItem.addEventListener('mouseleave', function(e){
                let el_link = this.querySelector('a[data-bs-toggle]');
                if (el_link != null){
                    let nextEl = el_link.nextElementSibling;
                    el_link.classList.remove('show');
                    nextEl.classList.remove('show');
                }
            })
        });
    }
    // else {
    //     document.querySelectorAll('.cat-filter-wrapper').forEach(function(everyItem){
    //         everyItem.removeEventListener('mouseover', function(e){
    //             let el_link = this.querySelector('a[data-bs-toggle]');
    //             if (el_link != null){
    //                 let nextEl = el_link.nextElementSibling;
    //                 el_link.classList.add('show');
    //                 nextEl.classList.add('show');
    //             }
    //         });
    //         everyItem.removeEventListener('mouseleave', function(e){
    //             let el_link = this.querySelector('a[data-bs-toggle]');
    //             if (el_link != null){
    //                 let nextEl = el_link.nextElementSibling;
    //                 el_link.classList.remove('show');
    //                 nextEl.classList.remove('show');
    //             }
    //         })
    //     });
    // }
}

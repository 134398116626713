// require('./bootstrap');
// import 'bootstrap'
import '@fortawesome/fontawesome-free/js/all';

import * as FilePond from 'filepond'
import FilePondPluginImageResize from 'filepond-plugin-image-resize'
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
// import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
// import FilePondPluginImageEdit from 'filepond-plugin-image-edit';

window.FilePond = FilePond;
window.FilePondPluginImageResize = FilePondPluginImageResize;
window.FilePondPluginImageTransform = FilePondPluginImageTransform;
window.FilePondPluginImagePreview = FilePondPluginImagePreview;
window.FilePondPluginFileValidateType = FilePondPluginFileValidateType;
window.FilePondPluginFileEncode = FilePondPluginFileEncode;
// window.FilePondPluginImageCrop = FilePondPluginImageCrop;
// window.FilePondPluginImageEdit = FilePondPluginImageEdit;

require('./nav');
require('./match-height');

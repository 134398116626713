function matchHeight(theClass = null){
    // Grab divs with the class name specified, default
    // to '.match-height' if none specified
    let divsToMatch = document.getElementsByClassName(theClass !== null ? theClass : 'match-height');

    // Find out how my divs there are with this class
    let arrayLength = divsToMatch.length;
    let heights = [];

    // Create a loop that iterates through the found divs and
    // pushes the heights of each to an empty array
    for (let i = 0; i < arrayLength; i++) {
        heights.push(divsToMatch[i].offsetHeight);
        // console.log('height of ' + i + ' : ' + divsToMatch[i].offsetHeight)
    }

    // Find the largest of the divs
    function getHighest() {
        // return Math.max(... heights)
        return heights.reduce((a,b) => Math.max(a,b), - Infinity);
    }

    // Set a variable equal to the tallest div
    let tallest = getHighest();
    // console.log(theClass +' tallest: ' + tallest);

    // Iterate through divs and set all their heights equal
    // to the tallest one
    for (let i = 0; i < divsToMatch.length; i++) {
        divsToMatch[i].style.height = tallest + "px";
    }
}

// Reset the heights to default ready to compare and resize.
function clearHeight(theClass = null) {

    let divsToMatch = document.getElementsByClassName(theClass !== null ? theClass : 'match-height');

    for (let i = 0; i < divsToMatch.length; i++) {
        divsToMatch[i].style.height = "auto";
        // console.log('default height of ' + i + ' : ' + divsToMatch[i].offsetHeight)
    }

    // console.log('reset heights')

}

function matchAllHeights() {
    matchHeight();
    matchHeight('match-height-2');
    matchHeight('match-height-3');
    matchHeight('match-height-4');
    matchHeight('match-height-5');
    matchHeight('match-height-listings');
    matchHeight('match-height-news');
    matchHeight('match-height-articles');
    matchHeight('match-height-videos');
}

function clearAllHeights() {
    clearHeight();
    clearHeight('match-height-2');
    clearHeight('match-height-3');
    clearHeight('match-height-4');
    clearHeight('match-height-5');
    clearHeight('match-height-listings');
    clearHeight('match-height-news');
    clearHeight('match-height-articles');
    clearHeight('match-height-videos');
}

let resizeIt;

// Immediately fire the resizer upon page load
document.addEventListener("DOMContentLoaded", function() {
    // clearTimeout(resizeIt);
    // resizeIt = setTimeout(matchAllHeights, 100);
    matchAllHeights();


    // Resize divs when window is resized. A short pause is included
    // so that the routine only fires when window resize is complete
    window.onresize = function () {
        clearAllHeights();
        // resizedWindow()
        clearTimeout(resizeIt);
        resizeIt = setTimeout(matchAllHeights, 100);
    }
});



// function resizedWindow() {
//
//     matchAllHeights();
//     console.log('resized');
// }
